<template>
  <div>
    <div style="position:absolute; top:15px;left:10px;pointer-events:none;" class="text-shadow">
      <div>视场:<span>{{view.fov}}</span></div>
      <div>水平:<span>{{view.hLookAt}}</span></div>
      <div>垂直:<span>{{view.vLookAt}}</span></div>
    </div>
    <div style="position:absolute;right:5px;top:15px;bottom:15px;" class="toolbar">
      <div class="text-shadow vtext" style="">
        垂直视角限制
      </div>
      <vue-slider v-model="vLookAtRange"
                  direction="ttb"
                  :min="-90"
                  :max="90"
                  :enable-cross="false"
                  :interval="0.1"
                  :contained="true"
                  :drag-on-click="true"
                  :tooltip="'always'"
                  style="display: inline-block;position:absolute;right:15px;top:5px;bottom:5px;">
        <template v-slot:dot="{ value, index, focus }">
          <template v-if="index==1">
            <div class="custom-dot" @dblclick="resetVLookAt"></div>
          </template>
        </template>
        <template v-slot:tooltip="{ value, index, focus }">
          <template v-if="index==1">
            <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-left" style="background-color:pink;border-color:pink;color:black;" @dblclick="resetVLookAt">
              <span class="vue-slider-dot-tooltip-text">{{ value }}</span>
            </div>
          </template>
        </template>
      </vue-slider>
    </div>
  </div>
</template>
<script>
  import VueSlider from 'vue-slider-component'
  export default {
    components: {
      VueSlider
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        view: {},
        vLookAtRange: [-90, 0, 90],
      }
    },
    watch: {
      vLookAtRange: function (newval, oldval) {
        var min = Math.min(newval[0], newval[2])
        var max = Math.max(newval[0], newval[2])
        this.getFunc({
          target: 'FullPano',
          name: 'setView'
        })({
          limitview: 'range',
          vlookatmin: min,
          vlookatmax: max,
          vlookat: newval[1],
        })
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          setPanoView: {
            target: 'Data',
            name: 'setPanoView',
          },
          outPanoView: {
            name: 'renewSence',
            arg: 'default',
          },
          tweenView: {
            target: 'FullPano',
            name: 'tweenView'
          },
        },
        funcs: {
          cancel: this.cancel,
          recoverView: this.recoverView,
          resetView: this.resetView,
          saveView: this.saveView,
          changeView: this.changeView,
          updateView: this.updateView,
        }
      })
      this.updateView()
      var min = Number(this.pano.vLookAtMin), max = Number(this.pano.vLookAtMax)
      if (!isNaN(min) && !isNaN(max) && (min != 0 || max != 0)) {
        this.vLookAtRange = [min, this.view.vLookAt, max]
      } else {
        this.vLookAtRange = [-90, this.view.vLookAt, 90]
      }
      for (var i in this.vLookAtRange) {
        console.log("vLookAtRange", i, this.vLookAtRange[i])
      }
    },
    destroyed() {
    },
    methods: {
      changeView(name) {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: `skin_${name}`
            }
          }
        )()
      },
      resetVLookAt() {
        console.log('dblclick')
        var view = {
          vlookat: 0,
        }
        this.$emit('tweenView', view)
      },
      updateView() {
        var v = this.getFunc({ target: 'FullPano', name: 'getView' })()
        this.view = {
          fov: Number(Number(v.fov).toFixed(1)),
          hLookAt: Number(this.math360(Number(v.hlookat)).toFixed(2)),
          vLookAt: Number(Number(v.vlookat).toFixed(2)),
          architectural: Number(v.architectural),
          pannini: Number(v.pannini),
          distortion: Number(v.distortion),
        }
        this.vLookAtRange[1] = this.view.vLookAt
      },
      recoverView() {
        var view = {
          fov: this.pano.fov,
          hlookat: this.pano.hLookAt,
          vlookat: this.pano.vLookAt,
        }
        this.$emit('tweenView', view)
      },
      resetView() {
        var view = {
          fov: 120,
          hlookat: 0,
          vlookat: 0,
        }
        this.$emit('tweenView', view)
      },
      saveView() {
        var max, min
        if (this.vLookAtRange[0] > this.vLookAtRange[2]) {
          max = this.vLookAtRange[0]
          min = this.vLookAtRange[2]
        } else {
          max = this.vLookAtRange[2]
          min = this.vLookAtRange[0]
        }
        this.$emit('setPanoView', {
          vLookAtMin: min,
          vLookAtMax: max,
          fov: this.view.fov,
          hLookAt: this.view.hLookAt,
          vLookAt: this.view.vLookAt,
          architectural: this.view.architectural,
          pannini: this.view.pannini,
          distortion: this.view.distortion,

        })
        this.$message({
          message: '设置视角成功',
          type: 'info',
          zIndex: 9999,
        })
      },
      cancel() {
        this.$emit('outPanoView')
      },
      math360(i) {
        while (i < 0 || i >= 360) {
          i = i > 0 ? i - 360 : i + 360
        }
        return i
      },
    },
  }
</script>
<style scoped>
  .custom-dot {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: pink;
    transition: all .3s;
  }
  .vtext {
    display: inline-block;
    height: 100%;
    font-size: 12px;
    writing-mode: vertical-lr;
    letter-spacing: 5px;
    text-align: center;
    min-height:400px;
  }
</style>
